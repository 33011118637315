import { toRaw } from "#imports";
export function gtag(...args) {
  window.dataLayer?.push(arguments);
}
export function initGtag({ tags }) {
  window.dataLayer = window.dataLayer || [];
  for (const tag of tags) {
    for (const command of tag.initCommands ?? [])
      gtag(...command);
  }
  gtag("js", /* @__PURE__ */ new Date());
  for (const tag of tags) {
    gtag("config", tag.id, tag.config ?? {});
  }
}
export function resolveTags(options) {
  const _options = toRaw(options);
  const tags = _options.tags.filter(Boolean).map((i) => typeof i === "string" ? { id: i } : i);
  if (_options.id) {
    const { id, config, initCommands } = _options;
    tags.unshift({ id, config, initCommands });
  }
  return tags;
}
