import { defu } from "defu";
import {
  useNuxtApp,
  useRequestEvent
} from "#imports";
export function useSiteConfig(options) {
  let stack;
  if (process.server)
    stack = useRequestEvent().context.siteConfig.get(defu({ resolveRefs: true }, options));
  else
    stack = useNuxtApp().$nuxtSiteConfig;
  return stack || {};
}
