import { useMemoize } from '@vueuse/core';
import type { Crdv, LowestMonthlyPayment } from '~/types/credova';

declare global {
  interface Window {
    CRDV: Crdv;
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:credova',
  dependsOn: [],
  parallel: true,
  setup: () => {
    const runtimeConfig = useRuntimeConfig().public.credova;

    const enabled = runtimeConfig.enabled;
    const minAmount = runtimeConfig.minAmount ?? 300;
    const maxAmount = runtimeConfig.maxAmount ?? 10000;

    const { status, load, onLoaded } = useScript(
      {
        src: 'https://plugin.credova.com/plugin.min.js',
        referrerpolicy: false,
        crossorigin: false,
      },
      {
        trigger: 'manual',
        mode: 'client',
        use: () => window.CRDV,
      });

    onLoaded(({ plugin }) => plugin.config({
      environment: window.CRDV.Environment[runtimeConfig.env]!,
      store: runtimeConfig.store,
      minAmount,
      maxAmount,
    }));

    const ready = computed(() => status.value == 'loaded');

    const valid = (amount: number) => enabled && amount >= minAmount && amount <= maxAmount;

    const getLowestMonthlyPayment = useMemoize((amount: number) => $fetch<LowestMonthlyPayment>(
      `/store/${runtimeConfig.store}/amount/${amount}/lowestMonthlyPayment`,
      {
        baseURL: 'https://lending-api.credova.com/v2/calculator',
        method: 'POST',
      }));

    const prequalify = (amount = minAmount) => load().then(({ plugin }) =>
      plugin.prequalify(amount));

    const checkout = (publicId: string) => load().then(({ plugin }) => plugin.checkout(publicId));

    return {
      provide: {
        credova: {
          enabled,
          ready,
          valid,
          getLowestMonthlyPayment,
          prequalify,
          checkout,
        },
      },
    };
  },
});
